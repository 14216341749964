<template>
  <div class="w-100" v-bind:style="sel_lang == 'ar' ? 'direction:rtl' : ''">
    <!-- Nav Bar -->

    <div>
      <nav class="navbar navbar-expand-lg navbar-light grey-bg">
        <div style="justify-content: flex-end; width: 100%; display: flex">
          <ul
            class="navbar-nav top-nav"
            style="
              display: flex;
              justify-content: space-between;
              width: 800px;
              font-size: 12px;
              align-items: center !important;
              flex-direction: row !important;
              flex-flow: wrap;
              margin: 0px 6px 0px 36px;
            "
          >
            <li class="cursor-pointer mr-5" @click="redirectGetting()">
              <i class="fa fa-map-pin" aria-hidden="true"></i>&nbsp;<span
                class="top-menu-name"
                >{{ $t("getting_here") }}</span
              >
            </li>
            <li class="cursor-pointer mr-5" @click="redirectFeedback()">
              <i class="fa fa-list-alt" aria-hidden="true"></i>&nbsp;<span
                class="top-menu-name"
                >{{ $t("feedback") }}</span
              >
            </li>
            <li class="cursor-pointer mr-5" @click="redirectChat()">
              <i class="fa fa-commenting" aria-hidden="true"></i>&nbsp;<span
                class="top-menu-name"
                >{{ $t("chat_with_us") }}</span
              >
            </li>
            <li class="cursor-pointer mr-5" @click="redirectLeasing()">
              <i class="fa fa-key" aria-hidden="true"></i>&nbsp;<span
                class="top-menu-name"
                >{{ $t("leasing") }}</span
              >
            </li>
            <li
              class="nav-item dropdown cursor-pointer mr-5"
              v-if="magazines.length > 0"
            >
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdownMenuLink"
                style="
                  text-transform: capitalize;
                  font-size: 12px;
                  font-weight: 400;
                "
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="fa fa-file-pdf-o" aria-hidden="true"></i>&nbsp;<span
                  class="top-menu-name"
                  >{{ $t("emagazine") }}</span
                >
              </a>

              <div
                class="dropdown-menu"
                aria-labelledby="navbarDropdownMenuLink"
              >
                <a class="dropdown-item" v-for="(mag, m) in magazines" :key="m">
                  <div
                    class="cursor-pointer"
                    @click.prevent="downloadMagzine(mag.file_upload)"
                  >
                    {{ mag.title }}
                  </div></a
                >
              </div>
            </li>

            <!-- Login/Logout Start -->
            <li
              class="cursor-pointer mr-5"
              @click="goToLoginPage()"
              v-if="!user"
            >
              <i class="fa fa-user" aria-hidden="true"></i
              ><span class="top-menu-name">{{ $t("login") }}</span>
            </li>
            <li v-else class="cursor-pointer mr-5">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarLogoutMenu"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style="text-transform: capitalize"
              >
                <i class="fa fa-user" aria-hidden="true"></i>
                <span class="top-menu-name">{{ user.firstname }}</span>
              </a>
              <div
                class="dropdown-menu"
                style="min-width: 43px; margin-right: 5px"
                aria-labelledby="navbarLogoutMenu"
              >
                <a
                  class="dropdown-item cursor-pointer"
                  @click="goToMyProfile()"
                  >{{ $t("my_services") }}</a
                >
                <a class="dropdown-item cursor-pointer" @click="logoutUser()">
                  <span class="top-menu-name">{{ $t("logout") }}</span></a
                >
              </div>
            </li>
            <!-- Login/Logout End -->
            <!-- login -->

            <li v-if="arabic_translation == 1" class="cursor-pointer mr-5">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="fa fa-globe"></i>&nbsp;{{ sel_lang }}
              </a>

              <div
                class="dropdown-menu mr-5"
                style="min-width: 43px"
                aria-labelledby="navbarDropdownMenuLink"
              >
                <a
                  class="dropdown-item cursor-pointer"
                  @click="setUserLang('en')"
                  >English</a
                >
                <a
                  class="dropdown-item cursor-pointer"
                  @click="setUserLang('ar')"
                  >العربية</a
                >
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </div>
    <!-- Nav Bar -->
  </div>
  <v-dialog v-model="dialog" max-width="70%">
    <v-card>
      <v-card-title class="d-flex justify-space-between">
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-btn color="primary" @click="downloadMagazine">Download</v-btn>
      </v-card-title>

      <v-card-text>
        <iframe :src="magazineUrl" class="magazine-iframe"></iframe>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
const default_layout = "default";
export default {
  components: {},
  data() {
    return {
      currentTheme: localStorage.getItem("theme-type"),
      envImagePath: process.env.VUE_APP_IMAGE_PATH,
      header_menu: [],
      magazines: [],
      sel_lang: "",
      user: "",
      dialog: false,
      magazineUrl: "",
      arabic_translation: 0,
    };
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || default_layout) + "-layout";
    },
    nextItems() {
      return this.notification_array.slice(
        this.currentIndex,
        this.currentIndex + this.itemsPerPage
      );
    },
  },

  mounted() {
    this.sel_lang = localStorage.getItem("pref_lang");
    this.user = JSON.parse(localStorage.getItem("customer_data"));
    this.getEmagzine();
  },
  created() {
    this.emitter.on("app_image_update", () => {});
    this.emitter.on("updateUserDetails", () => {
      this.user = JSON.parse(localStorage.getItem("customer_data"));
    });
  },
  watch: {
    "$i18n.locale"(newLocale) {
      if (newLocale === "ar") {
        this.sel_lang = "ar";
      } else {
        this.sel_lang = "en";
      }
    },
  },

  methods: {
    ...mapActions("auth", ["logoutUser"]),
    logoutUser() {
      this.$store
        .dispatch("auth/logoutUser")
        .then(() => {
          let newRoute = {
            name: "home",
          };
          this.$router.push(newRoute);
          this.$toast.success(this.$t("logout_success"));
          this.user = "";
        })
        .catch((error) => {
          console.error("Logout failed", error);
        });
    },
    downloadMagzine(image_url) {
      //window.open(this.envImagePath + image_url, "_blank");
      this.magazineUrl = this.envImagePath + image_url;
      this.dialog = true;
    },
    downloadMagazine() {
      if (this.magazineUrl) {
        // Perform download action here, e.g., using FileSaver.js or directly setting window.location
        window.open(this.magazineUrl, "_blank");
      }
    },
    getEmagzine() {
      this.$axios
        .get(process.env.VUE_APP_API_URL_ADMIN + "fetch-emagazine-lang")
        .then((res) => {
          this.initval = false;
          // this.$toast.success(this.array_data);
          // this.today_hours = res.data.todaysTiming;
          this.magazines = res.data.magazine;
          this.arabic_translation = res.data.arabic_translation;
        })
        .catch((err) => {
          this.$toast.error(this.array_data);
          console.log(" error" + err);
        });
    },
    setUserLang(lang) {
      if (lang == "ar") {
        localStorage.setItem("pref_lang", "ar");
        this.sel_lang = "ar";
        this.$i18n.locale = "ar";
        let newRoute = {
          ...this.$route,
          params: {
            ...this.$route.params,
            lang: "ar",
          },
        };
        console.log(newRoute);
        this.$router.push(newRoute).catch((err) => {
          if (err.name !== "NavigationDuplicated") {
            throw err;
          }
        });
        setTimeout(function () {
          window.location.reload(1);
        }, 500);
      } else {
        this.sel_lang = "en";
        localStorage.setItem("pref_lang", "en");
        this.$i18n.locale = "en";

        let newRoute = {
          ...this.$route,
          params: {
            ...this.$route.params,
            lang: "en",
          },
        };
        console.log(newRoute);
        this.$router.push(newRoute).catch((err) => {
          if (err.name !== "NavigationDuplicated") {
            throw err;
          }
        });
      }
      setTimeout(function () {
        window.location.reload(1);
      }, 500);
    },
    redirectGetting() {
      this.$router.push({ name: "gettinghere" });
    },
    redirectLeasing() {
      this.$router.push({ name: "leasing" });
    },
    redirectChat() {
      this.$router.push({ name: "chat-with-us" });
    },
    redirectFeedback() {
      this.$router.push({ name: "feedback" });
    },
    goToLoginPage() {
      this.$router.push({ name: "login" });
    },
    goToMyProfile() {
      this.$router.push({ name: "my_profile" });
    },
    // loadMore() {
    //   this.loader = true;
    //   this.displayedItems.push(...this.nextItems);
    //   this.currentIndex += this.itemsPerPage;
    //   if (!this.showLoadMoreButton) {
    //     console.log("All data loaded.");
    //   }
    //   this.loader = false;
    // },
  },
};
</script>
<style scoped>
.nav-link {
  color: #fff !important;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 13px;
}
.dropdown-item {
  font-size: 12px;
  padding: 1px 12px;
}

.top-menu-name {
  font-size: 14px !important;
}
.magazine-iframe {
  width: 100%;
  height: 400px; /* Adjust height as needed */
}
.fa {
  font-size: medium !important;
  margin-right: 5px !important;
}
/* .navbar-expand-lg .navbar-nav .dropdown-menu{left: 2px;} */
</style>

